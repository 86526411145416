import React from 'react';
import '../assets/css/login.scss';
import { BaseBtn } from 'Apps/App/layout/base/buttons/BaseBtn';

import logo from "Apps/App/assets/img/miixoa-dark-blue-logo-2.png"

export function Login() {

  return (

<main id="login" className="auto">
  <div className="login-form">
    <img className='w-300 ml-30' src={logo}/>

    <div className="headline b fs-1p9em align-c">Central</div>
    <div className="headline mb-20 fs-p9em align-c">
      Sign in to access your dashboard
    </div>

    <form className="login" >
      <div className="">
        <input
          type="email"
          id="email"
          placeholder="email"
          required
        />
      </div>

      <div className="mt-15">
        <input
          type="password"
          id="password"
          placeholder="password"
          required
        />
      </div>

      <BaseBtn  btnClass={"btz -fill-1 fs-1em ptb-14 b w-100p bdrd-6 mt-20"} name={"Sign in"}/> 
        
      {/* <p>{{ error }}</p> */}
    </form>
  </div>
</main>

  );
}

