import React from 'react';
import ReactDOM from 'react-dom/client';
import './Apps/App/assets/css/style.scss';
// import { Home } from './Apps/App/views/Home';
import { Login } from './Apps/Auth/views/Login';
import reportWebVitals from './reportWebVitals';

const root = ReactDOM.createRoot(
  document.getElementById('root') as HTMLElement
);
root.render(
  <React.StrictMode>
    <Login />
    {/* <Home /> */}
  </React.StrictMode>
);

reportWebVitals();
