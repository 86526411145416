

interface BtnProps {
    btnClass: string;
    name: string;
    // age: number;
    // country: string;
  }

export function BaseBtn({btnClass, name, }: BtnProps) {
    return (
        <button type="submit"  className={btnClass}>{name}</button>
    );

    
}